<template>
  <v-container>
    <v-btn text x-large elevation="2" color="primary" @click="downloadWorksheet()">
      {{ $t("download-worksheet") }}
    </v-btn>
    <v-btn text x-large elevation="2" color="primary" @click="setStatusToShipped()">
      {{ $t("set-status-to-shipped") }}
    </v-btn>
    <box-status />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              type="number"
              v-model="year"
              single-line
              hide-details
              v-if="!search"
              v-on:change="sanitizeYear(true)"
              :prefix="`${$t('year')}: `"
            />
          </v-col>
          <v-col cols="auto">
            <v-text-field
              type="number"
              v-model="limit"
              :prefix="`${$t('items-per-page')}: `"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :loading="loading"
        :item-class="itemRowBackground"
        item-key="PatientID"
        hide-default-footer
        sort-by="timestamp"
        sort-desc
        show-select
        :single-select="false"
        class="main-table"
      >
        <!-- :search="search" -->
        <template v-slot:item.dateTime="{ item }">
          {{ new Date(item.dateTime).toLocaleString($i18n.locale) }}
        </template>
        <template v-slot:item.sendTo="{ item }">
          <v-chip v-if="item.sendTo">
            {{ $t(item.sendTo) }}
          </v-chip>
        </template>
        <template v-slot:item.address="{ item }">
          <span v-html="formatAddress(item.address)"></span>
        </template>

        <template #footer>
          <v-container
            fluid
            class="d-flex"
            v-if="(records && records.LastEvaluatedKey) || pagination"
          >
            <v-btn
              depressed
              :disabled="!hasPrevious"
              :text="!hasPrevious"
              @click="query(0, 'fwd')"
            >
              {{ $t("previous") }}
            </v-btn>
            <v-spacer />
            <v-btn
              depressed
              :disabled="!hasNext"
              :text="!hasNext"
              @click="query(0, 'bwd')"
            >
              {{ $t("next") }}
            </v-btn>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import BoxStatus from "../components/BoxStatus.vue";
import SecureSaveMixin from "@/mixins/SecureSaveMixin";

const shajs = require("sha.js");

export default {
  name: "Worksheet",
  mixins : [SecureSaveMixin],
  components: { BoxStatus },
  data() {
    return {
      selected: [],
      search: null,
      year: new Date().getFullYear(),
      limit: 30,
      loading: true,
      options: { itemsPerPage: Infinity },
      queryDebounce: null,
      pagination: null
    };
  },
  mounted() {
    this.query();
  },
  computed: {
    ...mapState(["records"]),
    headers() {
      return [
        {
          text: this.$t("id"),
          align: "start",
          value: "PatientID"
        },
        { text: this.$t("name"), value: "name" },
        { text: this.$t("registered-at"), value: "dateTime" },
        { text: this.$t("address"), value: "address", sortable: false },
        { text: this.$t("send-to"), value: "sendTo" },
        { text: this.$t("comment"), value: "comment", sortable: false },
      ];
    },
    items() {
      if (!this.records) return [];
      return this.records.Items;
    },
    hasPrevious() {
      if (!this.pagination) return false;
      if (!this.pagination.ScanForward) return true;
      return !!this.records.LastEvaluatedKey;
    },
    hasNext() {
      if (!this.pagination) return !!this.records.LastEvaluatedKey;
      if (!this.pagination.ScanForward) return !!this.records.LastEvaluatedKey;
      return true;
    }
  },
  methods: {
    ...mapActions(["fetchRecords"]),
    async query(debounce = 0, pagination = null /* "fwd" || "bwd" || null */) {
      if (this.queryDebounce) window.clearTimeout(this.queryDebounce);
      if (debounce) {
        this.queryDebounce = window.setTimeout(() => this.query(), debounce);
        return;
      }

      this.loading = true;
      const params = {};
      
      if (this.$store.state.user.type != 'tech' || !this.search)
      {
        params.sortYear = parseInt(this.year);
      }
      
      if (this.$store.state.user.type != 'tech')
      {
        params.SearchUserID = this.$store.state.user.UserID;
      }
      
      if (this.limit) params.Limit = parseInt(this.limit);
      if (this.search) params.NameSearchString = this.search;

      if (pagination) {
        params.ScanForward = pagination === "fwd";
        const startKey = this.records.LastEvaluatedKey
          ? btoa(JSON.stringify(this.records.LastEvaluatedKey))
          : this.pagination.StartKey;
        params.StartKey = startKey;
        this.pagination = params;
      } else {
        this.pagination = null;
      }

      await this.fetchRecords(params);
      this.loading = false;
    },
    async setStatusToShipped() {
      if (!(await this.$confirm(this.$t("update-to-shipped-order-confirm"), {
          title: this.$t("warning"),
          buttonTrueText: this.$t("yes"),
          buttonTrueColor: "error",
          buttonFalseText: this.$t("cancel")
        }))
      ) {
        return;
      }
      
      for (var i = 0; i < this.selected.length; i++)
      {
        if (this.selected[i].orders !== undefined)
        {
          for (var j = 0; j < this.selected[i].orders.length; j++)
          {
            if (this.selected[i].orders[j].status == "order-status-progress")
            {
              this.selected[i].orders[j].status = "order-status-shipped";
            }
            
            if (this.selected[i].orders[j].status == "order-status-new")
            {
              if (!(await this.$confirm(this.$t("continue-status-update").replace("$PatientID", this.selected[i].PatientID), {
                  title: this.$t("error"),
                  buttonTrueText: this.$t("yes"),
                  buttonTrueColor: "error",
                  buttonFalseText: this.$t("cancel")
                }))
              ) {
                return;
              }
            }
          }
        }
        
        await this.secureSavePatientRecord(this.selected[i], true);
      }
      
      this.selected = [];
    },
    async downloadWorksheet() {
      if (!(await this.$confirm(this.$t("update-to-inprogress-order-confirm"), {
          title: this.$t("warning"),
          buttonTrueText: this.$t("yes"),
          buttonTrueColor: "error",
          buttonFalseText: this.$t("cancel")
        }))
      ) {
        return;
      }
      
      if (this.selected.find((item) => item.orders !== undefined) === undefined)
      {
        alert(this.$t("missing-orders"));
        return;
      }
      
      for (var i = 0; i < this.selected.length; i++)
      {
        if (this.selected[i].orders !== undefined)
        {
          for (var j = 0; j < this.selected[i].orders.length; j++)
          {
            if (this.selected[i].orders[j].status == "order-status-new")
            {
              this.selected[i].orders[j].status = "order-status-progress";
            }
          }
          
          await this.secureSavePatientRecord(this.selected[i], true);
        }
      }
      
      var PatientIDs = this.selected.map(item => item.PatientID).sort();
      
      PatientIDs = JSON.stringify(PatientIDs);
      const HexHash = shajs("sha256").update(PatientIDs).digest("hex");
      console.log(HexHash.substring(0,8));
      
      var response = await this.$store.dispatch("pdfWorksheet", btoa(PatientIDs));
      
      console.log(response);
      
      var pdfURL = response.link;
      
      try {
        
        response = await fetch(pdfURL);
        var pdfBlob = await response.blob();
        
        const data = window.URL.createObjectURL(pdfBlob);
        var link = document.createElement('a');
        
        link.href = data;
        link.download = this.$t("worksheet") + "_" + HexHash.substring(0,8) + ".pdf";
        link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
        link.click();
      } catch (err)
      {
        window.open(pdfURL);
      }
      
      this.selected = [];
    },
    formatAddress(address) {
      if (!address) return "";
      const items = [];
      if (address.streetAddress) items.push(address.streetAddress);
      if (address.zipCode) items.push(address.zipCode);
      if (address.city) items.push(address.city);
      if (address.country) items.push(address.country);
      return items.join(", ");
    },
    itemRowBackground(item) {
      var hasNew = false;
      var hasInProgress = false;
      var hasShipped = false;
      var hasOrders = false;
      
      if (item.orders)
      {
        //"order-status-new"
        for (var i = 0; i < item.orders.length; i++)
        {
          hasOrders = true;
          if (item.orders[i].status == "order-status-new")
          {
            hasNew = true;
          }
          
          if (item.orders[i].status == "order-status-progress")
          {
            hasInProgress = true;
          }
          
          if (item.orders[i].status == "order-status-shipped")
          {
            hasShipped = true;
          }
        }
      }
      
      if (hasNew) {
        return "itemWithNewOrder";
      }
      if (hasInProgress) {
        return "itemWithNewInProgressOrder";
      }
      if (hasShipped) {
        return "itemWithShipped";
      }
      if (!hasOrders)
      {
        return "itemWithoutOrder";
      }
    },
    sanitizeYear(doChange) {
      if (this.year < 1990)
      {
        if (doChange)
        {
          this.year = 1990;
        }
        
        return false;
      }
      
      if (this.year > (new Date().getFullYear()+1))
      {
        if (doChange)
        {
          this.year = new Date().getFullYear();
        }
        
        return false;
      }
      
      return true;
    }
  },
  watch: {
    year() {
      if (this.sanitizeYear(false)) {
        this.query(200);
      }
    },
    limit() {
      this.query(200);
    },
    search() {
      this.query(200);
    }
  }
};
</script>
<style lang="scss">
.main-table tr {
  cursor: pointer;
}
.itemWithNewOrder {
  background-color: #FFA0A0;
}
.itemWithNewInProgressOrder {
  background-color: #FFFFA0;
}
.itemWithShipped {
  background-color: #A0FFA0;
}
.itemWithoutOrder {
  background-color: #A0FFFF;
}
.main-table tr.v-data-table__selected {
  background: #f090f0 !important;
}
</style>
